<script setup>
import Carousel from "~/components/molecules/Carousel.vue";
import Text from "~/components/atoms/Text.vue";
import List from "~/components/molecules/List.vue";

const props = defineProps({
  data: Object,
})

const loaded = ref(false);

onMounted(() => {
  loaded.value = true;

  setTimeout(() => {
    document.querySelectorAll(".slide-hover .title").forEach((el) => {
      const text = el.textContent.trim();
      const words = text.split(" ");
      el.innerHTML = "";

      let currentLineTop = 0;
      let lastLineSpans = [];

      words.forEach((word, index) => {
        const span = document.createElement("span");
        span.textContent = word + " ";
        el.appendChild(span);

        if (span.offsetTop > currentLineTop) {
          currentLineTop = span.offsetTop;
          lastLineSpans = [span];
        } else {
          lastLineSpans.push(span);
        }
      });

      lastLineSpans.forEach((span) => {
        span.style.textDecoration = "underline";
        span.style.textUnderlinePosition = "under";
      });
    });
  }, 500)
});

</script>

<template>
  <div class="high-slider">
    <template v-if="!$device.isMobile && !$device.isTablet">
      <Carousel :infinity="true" :items="data.items" :customBreakpoints="{
        0: { itemsToShow: 1 },
        600: { itemsToShow: 2 },
900: { itemsToShow: 2.7 },
1150: { itemsToShow: 3 },
1330: { itemsToShow: 3.2 },
1450: { itemsToShow: 3.5 },
      }" :items-to-scroll="1" :items-to-show="3.5">
        <template #default="{item}">
          <nuxt-link :to="item.url" class="slide">
            <img :src="item.image" alt=""/>

            <div class="content">
              <div class="g-12">
                <Text tag="strong" class="title">{{ item.title }}</Text>
                <Text>{{ item.subtitle }}</Text>
              </div>

              <Text class="more">{{ item.more }}</Text>
            </div>

            <div class="slide-hover g-72" v-if="loaded">
              <Text textAlignment="left" uppercase tag="strong" class="title">{{
                  item.title
                }}
              </Text>

              <List class="list text-left" :items="item.menu" no-prefix font-size="16px">
                <template #default="{item}">
                  <nuxt-link :to="item.link">{{ item.title }}</nuxt-link>
                </template>
              </List>
            </div>
          </nuxt-link>
        </template>
      </Carousel>
    </template>
    <template v-else>
      <div class="mobile-block">
        <nuxt-link :to="item.url" class="mobile-slide" v-for="item in data.items">
          <img :src="item.mobile_image" alt=""/>

          <div class="content">
            <div class="g-12">
              <Text tag="strong" class="title">{{ item.title }}</Text>
              <Text>{{ item.subtitle }}</Text>
            </div>
          </div>

        </nuxt-link>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.slide {
  width: 100%;
  height: 600px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 80px 48px;
    text-align: left;
    color: white;
    font-size: 18px;

    .more {
      margin-top: auto;
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  .title {
    font-size: 36px;
    line-height: 39.35px;
    text-transform: uppercase;
  }

  .slide-hover {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: $grey;
    padding: 80px 48px;
    opacity: 0;
    transition: 0.3s opacity ease-in-out;
    justify-content: flex-start;

    .title {
      min-height: 78px;
      max-height: 78px;
    }
  }


  &:hover {
    .slide-hover {
      opacity: 1;
    }
  }
}

.high-slider {
  @include mobile {
    margin: 0 -16px;
  }

  &:deep(.swiper-btn) {
    left: -25px;
  }
}

.high-slider:deep(.swiper-btn.right) {
  left: unset;
  right: -25px;
}

.mobile-block {
  .mobile-slide {
    height: 200px;
    overflow: hidden;

    .content {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: left;
      color: white;
      font-size: 12px;
      padding: 26px 16px;

      .title {
        font-size: 18px;
        text-transform: uppercase;
        line-height: 21.6px;
      }
    }

    img {
      width: 100%;
    }
  }
}

.list {
  gap: 0;
  line-height: 30px;

  a {
    display: inline-block;
    line-height: 1.6;
    text-transform: uppercase;
    letter-spacing: 0.64px;

    &:hover {
      font-weight: 600;
    }
  }
}
</style>
